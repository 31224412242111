

      $(function() {
          $("#registerForm #send_mail_register").click(function() {
            // Get the form.
            var form = $('#registerForm'); 
            var formMessages = $('#form-messages');  
            $(form).submit(function(event) { 
                event.preventDefault(); 
            });
            var formData = $(form).serialize();

            var imgVal = $('#file').val(); 

            
            $.ajax({
                type: 'POST',
                url: $(form).attr('action'),
                data: formData
            }) 
            .done(function(response) {
                // Make sure that the formMessages div has the 'success' class.
                $(formMessages).removeClass('text-danger');
                $(formMessages).addClass('text-success');
      
                // Set the message text.
                $(formMessages).text(response);
      
                // Clear the form.
                $('#name').val('');
                $('#email').val('');
                $('#message').val('');
            })
      
            .fail(function(data) {
                // Make sure that the formMessages div has the 'error' class.
                $(formMessages).removeClass('text-success');
                $(formMessages).addClass('text-danger');
      
                // Set the message text.
                if (data.responseText !== '') {
                    $(formMessages).text(data.responseText);
                } else {
                    $(formMessages).text('Oops! An error occurred and your message could not be sent.');
                }
            });
      
          });
        });
      
      


$(function() {
    $("#sendMailForm #send_mail").click(function() {
      // Get the form.
      var form = $('#sendMailForm');

      // Get the messages div.
      var formMessages = $('#form-messages'); 

      // Set up an event listener for the contact form.
      $(form).submit(function(event) {
          // Stop the browser from submitting the form.
          event.preventDefault();

          // TODO
      });

 
      var formData = $(form).serialize();
      console.log(formData); 
      $.ajax({
          type: 'POST',
          url: $(form).attr('action'),
          data: formData
      }) 
      .done(function(response) {
          // Make sure that the formMessages div has the 'success' class.
          $(formMessages).removeClass('text-danger');
          $(formMessages).addClass('text-success');

          // Set the message text.
          $(formMessages).text(response);

          // Clear the form.
          $('#name').val('');
          $('#email').val('');
          $('#message').val('');
      })

      .fail(function(data) {
          // Make sure that the formMessages div has the 'error' class.
          $(formMessages).removeClass('text-success');
          $(formMessages).addClass('text-danger');

          // Set the message text.
          if (data.responseText !== '') {
              $(formMessages).text(data.responseText);
          } else {
              $(formMessages).text('Oops! An error occurred and your message could not be sent.');
          }
      });

    });
  });











$(document).ready(function (e) {
    $("#ResumeSubmit").on('submit', function(e){

      e.preventDefault();
      var formMessages = $('#form-messages'); 

      // $(form).submit(function(event) { event.preventDefault();  });

      // var formData = {
      //       name: $('#name').val(),
      //       phone: $('#phone').val(),
      //       file: $('#file').prop('files')[0]
      // };

      $.ajax({
          type: 'POST',
          url: $("#ResumeSubmit").attr('action'),
          data: new FormData(this),
          contentType: false,
            cache: false,
            processData:false,
      }) 
      
      .done(function(response) { 
        console.log(response);
          $(formMessages).removeClass('text-danger');
          $(formMessages).addClass('text-success');

          // Set the message text.
          $(formMessages).text(response);

          // Clear the form.
          $('#name').val('');
          $('#phone').val('');
          $('#file').val('');
      })

      .fail(function(data) {
          $(formMessages).removeClass('text-success');
          $(formMessages).addClass('text-danger');
          
          if (data.responseText !== '') {
              $(formMessages).text(data.responseText);
          } else {
              $(formMessages).text('Oops! An error occurred and your message could not be sent.');
          }
      });

    });
  });
