"use strict"
$(function(){
    //open modal to get started
    $(".modal-open").on("click", function(){
        $(".dm-modal").css({"display":"flex"});
    });
    $(".dm-modal__content-close").on("click", function() {
        $(".dm-modal").css({"display":"none"});
    });

    //jquery nice select
    $('.dm-select').niceSelect();

    //jquery owl s
    var team_slider = $('.career_slider')
    if (team_slider.length) {
        team_slider.owlCarousel({
            items: 3,
            autoplayHoverPause: true,
            loop: true,
            nav: false,
            autoplay: true,
            smartSpeed: 1000,
            responsive: {
                0: {
                    items: 1,
                    margin: 15,
                },
                768: {
                    items: 3,
                    margin: 15,
                }
            }
        });
    }

    //Sticky top
    $(window).scroll(function () {
        var window_top = $(window).scrollTop() + 1;
        if (window_top > 60) {
          $('.header').addClass('t-nav-sticky');
        } else {
          $('.header').removeClass('t-nav-sticky');
        }
      });
    
});